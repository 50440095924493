<template>
  <div>
    <PageHeader
      :title="`${role}s`"
      :description="`Gestion des ${role}s`"
      :button="{
        icon: 'fas fa-plus-circle',
        text: `Ajouter un ${role}`,
        action: create
      }"
    />
    <UsersSubmenu />

    <PageContent>
      <MVPTable
        :grid-data="gridData"
        :options="gridOptions"
        @selectionChanged="selectedUsers = $event"
        @rowDoubleClicked="openModal"
      />
      <button
        v-if="selectedUsers.length > 0"
        class="button is-danger"
        @click="deleteUsers"
      >
        <i class="fas fa-trash"></i>
      </button>
    </PageContent>

    <t-modal
      name="user-form"
      variant="contentForm"
      @before-open="modalOnBeforeOpen"
    >
      <MVPUsersForm
        v-if="currentUser"
        :user="currentUser"
        :userKey="currentUser.uuid"
        :role="$route.params.role"
        :groups="groups"
        @refresh="loadUsers"
      />
    </t-modal>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import UsersSubmenu from './UsersSubmenu.vue'
import CreateUser from './Modals/Create'
import MVPUsersForm from './Form.vue'

export default {
  name: 'app-users-list',
  components: {
    PageHeader,
    PageContent,
    UsersSubmenu,
    MVPTable,
    MVPUsersForm
  },
  computed: {
    domain () {
      // this.loadUsers()
      return this.$store.getters.domain
    },
    role () {
      return this.roles[this.$route.params.role]
    }
  },
  data () {
    return {
      loading: false,
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      users: [],
      groups: [],
      currentUser: null,
      selectedUsers: [],
      roles: {
        'user': 'Utilisateur',
        'community-manager': 'Community manager',
        'operator': 'Observateur',
        'director': 'Administrateur',
        'account-manager': 'Account manager'
      },
      gridData: null,
      gridOptions: {
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        columnDefs: [
          {
            headerName: 'Nom',
            field: 'lastname',
            filter: 'agSetColumnFilter',
            checkboxSelection: true,
            sortable: true
          },
          {
            headerName: 'Prénom',
            field: 'firstname',
            filter: 'agTextColumnFilter',
            sortable: true
          },
          {
            headerName: 'Adresse e-mail',
            field: 'email',
            filter: 'agTextColumnFilter',
            sortable: true
          },
          {
            headerName: 'Groupes',
            field: 'groups',
            filter: 'agTextColumnFilter',
            cellRenderer: 'ArrayCellRenderer',
            valueFormatter: (params) => {
              if (params.value) {
                return params.value.map((group) => group.label).join(', ')
              }
              return ''
            }
          },
          {
            headerName: 'Date inscription',
            field: 'email_verified_at',
            type: ['dateColumnFilterable'],
            cellRenderer: 'DateCellRenderer',
            sortable: true
          },
          {
            headerName: 'Téléchargé',
            headerClass: 'ag-center-aligned-header',
            field: 'is_verified',
            sortable: true,
            cellRenderer: 'BooleanCellRenderer',
            width: 150,
            cellStyle: {
              'textAlign': 'center'
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              cellRenderer: 'BooleanFilterCellRenderer',
              buttons: ['reset']
            }
          }
          // {
          //   headerName: 'Dernière connexion',
          //   field: 'updated_at', // fix
          //   filter: 'agDateColumnFilter',
          //   valueFormatter: dateFormatter,
          //   sortable: true
          // },
          // {
          //   headerName: 'Connexions',
          //   type: 'numericColumn',
          //   field: 'logins_count',
          //   sortable: true,
          //   width: 150
          // },
          // {
          //   headerName: 'Articles lus',
          //   type: 'numericColumn',
          //   field: 'article_consultations_count',
          //   sortable: true,
          //   width: 150
          // },
          // {
          //   headerName: 'Questions lues',
          //   type: 'numericColumn',
          //   field: 'faq_consultations_count',
          //   sortable: true,
          //   width: 180
          // }
        ]
      }
    }
  },
  created () {
    this.loadGroups()
    this.loadUsers()
  },
  watch: {
    role (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.loadGroups()
        this.loadUsers()
      }
    },
    domain (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.loadGroups()
        this.loadUsers()
      }
    }
  },
  methods: {
    goToUser ({ data }) {
      this.$router.push({ path: `/app/user/${data.uuid}` })
    },
    loadGroups () {
      http
        .get('/groups')
        .then(res => {
          this.groups = res.data
        })
    },
    loadUsers () {
      this.loading = true
      http.get(`/${this.$route.params.role}`).then(res => {
        this.loading = false
        this.users = res.data
        this.gridData = res.data
        // this.buildTable(res.data)
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.$buefy.notification.open({
          duration: 3000,
          message: err.response.data.message,
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    async deleteUsers () {
      for (let user of this.selectedUsers) {
        try {
          await http.delete(`/user/${user.uuid}`)
          this.$buefy.notification.open({
            duration: 4000,
            message: `L'utilisateur ${user.email} a été supprimé avec succès !`,
            position: 'is-bottom-left',
            type: 'is-success',
            hasIcon: true
          })
        } catch (err) {
          console.log(err)
          this.$buefy.notification.open({
            duration: 3000,
            message: err.response.data.message,
            position: 'is-bottom-left',
            type: 'is-warning',
            hasIcon: true,
            icon: 'server'
          })
        }
        this.loadUsers()
      }
    },
    create () {
      this.$buefy.modal.open({
        parent: this,
        component: CreateUser,
        hasModalCard: true,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        props: {
          role: this.$route.params.role
        },
        events: {
          created: (created) => {
            this.users.push(created)
            this.$buefy.notification.open({
              duration: 4000,
              message: `L'utilisateur ${created.email} a été créé avec succès !`,
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          }
        }
      })
    },
    modalOnBeforeOpen ({ params, cancel }) {
      console.log('open modal', params)
    },
    openModal ({ data }) {
      this.currentUser = data ?? {}
      console.log('open modal', data)
      this.$modal.show('user-form', data)
    }
  }
}
</script>
